:where(:not(html, iframe, canvas, img, svg, video, audio):not(svg *, symbol *)) {
  all: unset;
  display: revert;
}

*, :before, :after {
  box-sizing: border-box;
}

a, button {
  cursor: revert;
}

ol, ul, menu {
  list-style: none;
}

img {
  max-block-size: 100%;
  max-inline-size: 100%;
}

table {
  border-collapse: collapse;
}

input, textarea {
  -webkit-user-select: auto;
}

textarea {
  white-space: revert;
}

meter {
  appearance: revert;
}

pre {
  all: revert;
}

::placeholder {
  color: unset;
}

::marker {
  content: initial;
}

:where([hidden]) {
  display: none;
}

:where([contenteditable]:not([contenteditable="false"])) {
  -moz-user-modify: read-write;
  -webkit-user-modify: read-write;
  overflow-wrap: break-word;
  -webkit-line-break: after-white-space;
  -webkit-user-select: auto;
}

:where([draggable="true"]) {
  -webkit-user-drag: element;
}

@font-face {
  font-family: Gotham-Black;
  src: url("Gotham-Black.c6cedb98.woff2") format("woff2");
}

@font-face {
  font-family: Gotham-Bold;
  src: url("Gotham-Bold.cd43f69a.woff2") format("woff2");
}

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham-Book.f036558a.woff2") format("woff2");
  font-weight: 400;
}

@font-face {
  font-family: Gotham-Light;
  src: url("Gotham-Light.d715b493.woff2") format("woff2");
  font-weight: 300;
}

@font-face {
  font-family: Gotham-Medium;
  font-style: normal;
  font-weight: 500;
  src: url("Gotham-Medium.5971cac3.otf") format("opentype");
}

html, body, .rlcip-VC4pIW-book {
  font-family: Gotham-Book, sans-serif;
}

.rlcip-VC4pIW-medium {
  font-family: Gotham-Medium, sans-serif;
}

.rlcip-VC4pIW-bold {
  font-family: Gotham-Bold, sans-serif;
}

.rlcip-VC4pIW-black {
  font-family: Gotham-Black, sans-serif;
}

.rlcip-VC4pIW-size-small {
  font-size: .625rem;
}

.rlcip-VC4pIW-size-normal {
  font-size: .75rem;
}

.rlcip-VC4pIW-size-medium {
  font-size: .875rem;
}

.rlcip-VC4pIW-size-large {
  font-size: 1rem;
}

.rlcip-VC4pIW-spacing-normal {
  letter-spacing: .0625rem;
}

.rlcip-VC4pIW-spacing-medium {
  letter-spacing: .203rem;
}

.rlcip-VC4pIW-spacing-large {
  letter-spacing: 2rem;
}

.rlcip-VC4pIW-spacing-xlarge {
  letter-spacing: 2.375rem;
}

.rlcip-VC4pIW-gap-none {
  gap: 0;
}

.rlcip-VC4pIW-gap-normal {
  gap: .5rem;
}

.rlcip-VC4pIW-gap-medium {
  gap: 1rem;
}

.rlcip-VC4pIW-gap-large {
  gap: 1.5rem;
}

.rlcip-VC4pIW-gap-xlarge {
  gap: 2.5rem;
}

.rlcip-VC4pIW-w100 {
  width: 100%;
}

.rlcip-VC4pIW-main {
  color: var(--main-container-foreground-color);
  background: var(--main-container-background-color);
  height: 100%;
}

.rlcip-VC4pIW-button {
  text-transform: uppercase;
  border: 1px solid #f1efef;
  border-radius: .1875rem;
  outline: none;
  justify-content: center;
  align-items: center;
  min-width: 2rem;
  height: 2.375rem;
  padding: .875rem 2rem;
  transition: all .15s ease-in-out;
  display: flex;
}

.rlcip-VC4pIW-button:enabled:hover {
  cursor: pointer;
}

.rlcip-VC4pIW-button:disabled, .rlcip-VC4pIW-button[disabled] {
  opacity: .5;
}

.rlcip-VC4pIW-button:disabled:hover, .rlcip-VC4pIW-button[disabled][hover] {
  cursor: auto;
}

.rlcip-VC4pIW-button:enabled span:hover {
  opacity: .2;
}

@media (width >= 768px) {
  .rlcip-VC4pIW-main {
    height: 100%;
  }

  .rlcip-VC4pIW-size-large {
    font-size: 1.375rem;
  }
}

.rlcip-BOldLq-inline {
  flex-flow: row;
  justify-content: center;
  align-items: center;
  display: flex;
}

.rlcip-BOldLq-gap-normal {
}

.rlcip-BOldLq-gap-medium {
}

.rlcip-BOldLq-gap-large {
}

.rlcip-BOldLq-gap-xlarge {
}

.rlcip-BOldLq-valign-start {
  align-items: flex-start;
}

.rlcip-BOldLq-valign-center {
  align-items: center;
}

.rlcip-BOldLq-valign-end {
  align-items: flex-end;
}

.rlcip-BOldLq-halign-start {
  justify-content: flex-start;
}

.rlcip-BOldLq-halign-center {
  justify-content: center;
}

.rlcip-BOldLq-halign-end {
  justify-content: flex-end;
}

.rlcip-BOldLq-halign-space-between {
  justify-content: space-between;
}

.rlcip-BOldLq-reverse {
  flex-direction: row-reverse;
}

.rlcip-FTnJVa-container {
  padding: 0 1rem;
}

.rlcip-FTnJVa-logo svg {
  transform-origin: 0;
  transform: scale(.6);
}

.rlcip-FTnJVa-virtualTourButton, .rlcip-FTnJVa-themeButton {
  display: none;
}

@media (width >= 768px) {
  .rlcip-FTnJVa-container {
    padding: 1rem 3.125rem;
  }

  .rlcip-FTnJVa-virtualTourButton {
    display: flex;
  }

  .rlcip-FTnJVa-logo {
    align-items: center;
    display: flex;
  }

  .rlcip-FTnJVa-logo svg {
    transform-origin: initial;
    transform: scale(1);
  }

  .rlcip-FTnJVa-themeButton {
    justify-content: flex-end;
    align-items: center;
    display: flex;
  }

  .rlcip-FTnJVa-menuButton {
    display: none;
  }
}

.rlcip-FwxsdG-size-small {
}

.rlcip-FwxsdG-size-normal {
}

.rlcip-FwxsdG-size-medium {
}

.rlcip-FwxsdG-size-large {
}

.rlcip-FwxsdG-spacing-normal {
}

.rlcip-FwxsdG-spacing-medium {
}

.rlcip-FwxsdG-spacing-large {
}

.rlcip-FwxsdG-spacing-xlarge {
}

.rlcip-FwxsdG-weight-normal {
}

.rlcip-FwxsdG-weight-medium {
}

.rlcip-FwxsdG-weight-bold {
}

.rlcip-FwxsdG-weight-strong {
}

.rlcip-eFaNna-primary {
  background-color: var(--button-primary-background-color);
  color: var(--button-primary-text-color);
}

.rlcip-eFaNna-secondary {
  background-color: var(--button-secondary-background-color);
  color: var(--button-secondary-text-color);
}

.rlcip-eFaNna-purchase {
  background-color: var(--button-purchase-background--color);
  color: var(--button-purchase-text-color);
  border: 1px solid #0000;
}

.rlcip-eFaNna-customize {
}

.rlcip-eFaNna-customize:enabled span:hover {
  opacity: unset;
}

.rlcip-eFaNna-customize:hover {
  background-color: var(--button-secondary-text-color);
  color: var(--button-secondary-background-color);
}

.rlcip-Whmyva-textIcon {
  color: var(--textIcon-color);
  text-transform: uppercase;
  outline: none;
  justify-content: center;
  align-items: center;
  height: 2.375rem;
  padding: 0;
  transition: all .15s ease-in-out;
  display: flex;
}

.rlcip-Whmyva-textIcon:hover {
  opacity: 1;
  text-decoration: underline;
}

.rlcip-dLYzjW-main {
  flex-direction: column;
  height: 100%;
  display: flex;
  position: relative;
  overflow-x: hidden;
}

.rlcip-dLYzjW-contents {
  height: 100%;
}

.rlcip-dLYzjW-header {
  z-index: 6;
  background-color: var(--main-container-background-color);
  opacity: 1;
  height: 3.57rem;
  transition: all .2s ease-in-out;
  position: relative;
}

.rlcip-dLYzjW-menuContainer {
  background-color: var(--menu-footer-background-color);
  z-index: 4;
  opacity: 1;
  width: 100%;
  transition: all .2s ease-in-out;
  position: absolute;
  inset: 3.57rem 0 0 100%;
}

.rlcip-dLYzjW-menuContainerOpened {
  z-index: 4;
  left: 0;
}

.rlcip-dLYzjW-variantSelector {
  z-index: 3;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 5.06em;
  padding: 0;
  display: flex;
}

.rlcip-dLYzjW-footer {
  opacity: 1;
  transition: all .2s ease-in-out;
}

.rlcip-dLYzjW-purchaseFooter {
  height: 12rem;
}

.rlcip-dLYzjW-footerButtonContainer {
  padding: .3125rem 1rem .875rem;
}

.rlcip-dLYzjW-productInfo {
  justify-content: center;
  align-items: center;
  transition: all .2s ease-in-out;
  display: flex;
  position: absolute;
  top: 4rem;
}

.rlcip-dLYzjW-productInfoContainer {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: .5rem;
  display: flex;
}

button {
  cursor: pointer;
}

.rlcip-dLYzjW-loading {
  position: absolute;
  top: 100%;
  left: 100%;
}

.rlcip-dLYzjW-ready {
  position: static;
  transform: translate(0);
}

.rlcip-dLYzjW-hide {
  opacity: 0;
  z-index: -1;
}

.rlcip-dLYzjW-loader {
  color: #e53238;
  box-sizing: border-box;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  margin: 15px auto;
  animation: 2s linear infinite rlcip-dLYzjW-animloader;
  display: block;
  position: relative;
  transform: scale(1.5);
}

@keyframes rlcip-dLYzjW-animloader {
  0% {
    box-shadow: 14px 0 0 -2px, 38px 0 0 -2px, -14px 0 0 -2px, -38px 0 0 -2px;
  }

  25% {
    box-shadow: 14px 0 0 -2px, 38px 0 0 -2px, -14px 0 0 -2px, -38px 0 0 2px;
  }

  50% {
    box-shadow: 14px 0 0 -2px, 38px 0 0 -2px, -14px 0 0 2px, -38px 0 0 -2px;
  }

  75% {
    box-shadow: 14px 0 0 2px, 38px 0 0 -2px, -14px 0 0 -2px, -38px 0 0 -2px;
  }

  100% {
    box-shadow: 14px 0 0 -2px, 38px 0 0 2px, -14px 0 0 -2px, -38px 0 0 -2px;
  }
}

@media (width >= 768px) {
  .rlcip-dLYzjW-viewerContainer {
    width: 100%;
    height: 100%;
  }

  .rlcip-dLYzjW-menuContainer {
    top: 0;
    bottom: 0;
    left: unset;
    background-color: #0000;
    width: 21.06rem;
    transition: none;
    right: 0;
  }

  .rlcip-dLYzjW-variantSelector {
    z-index: 4;
    padding: 1rem 0 0;
  }

  .rlcip-dLYzjW-menuFooter {
    display: none;
  }

  .rlcip-dLYzjW-productInfo {
    justify-content: flex-start;
    align-items: center;
    width: auto;
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 3.125rem;
  }

  .rlcip-dLYzjW-productInfoContainer {
    align-items: flex-start;
  }

  .rlcip-dLYzjW-buttonContainer:hover {
    cursor: pointer;
    transform: scale(1.1);
  }

  .rlcip-dLYzjW-backLabel, .rlcip-dLYzjW-resetLabel {
    display: block;
  }

  .rlcip-dLYzjW-footerButtonBack {
    visibility: hidden;
  }
}

.rlcip-FkUaYW-container {
  z-index: -1;
  justify-content: center;
  align-items: center;
  transition: all .2s ease-in;
  display: flex;
  position: absolute;
  inset: 0;
}

.rlcip-FkUaYW-containerOpened {
  z-index: 99;
  transition: all .2s ease-in;
}

.rlcip-FkUaYW-overlay {
  background: var(--main-container-background-color);
  opacity: 0;
  transition: all .1s ease-in;
  position: absolute;
  inset: 0;
}

.rlcip-FkUaYW-overlayOpened {
  opacity: .6;
}

.rlcip-FkUaYW-modal {
  opacity: 0;
  background-color: var(--menu-footer-background-color);
  border: 1px solid #979797;
  justify-content: center;
  align-items: flex-end;
  width: 20rem;
  height: 14rem;
  padding: 0 0 1rem;
  transition: all .2s ease-in;
  display: flex;
  position: absolute;
  scale: 0;
}

.rlcip-FkUaYW-modalContent {
  width: 16rem;
  height: 10rem;
}

.rlcip-FkUaYW-modalOpened {
  opacity: 1;
  scale: 1;
}

.rlcip-FkUaYW-title {
  color: #e53238;
  text-align: center;
  text-transform: uppercase;
  font-size: .8165rem;
}

.rlcip-FkUaYW-message {
  text-align: center;
  color: var(--main-container-foreground-color);
  text-transform: uppercase;
  white-space: pre-line;
  line-height: 1.2rem;
  overflow-y: auto;
}

.rlcip-FkUaYW-buttonClose {
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: .6rem;
  right: .6rem;
}

.rlcip-FkUaYW-buttonClose:hover {
  cursor: pointer;
  transform: scale(1.1);
}

@media (width >= 768px) {
  .rlcip-FkUaYW-modal {
    align-items: center;
    width: 47rem;
    height: 22rem;
    padding: 2rem 0;
    position: relative;
  }

  .rlcip-FkUaYW-modalContent {
    width: 29rem;
    height: 13.3rem;
    padding: 0 1.81rem;
  }

  .rlcip-FkUaYW-message {
    line-height: 1.5rem;
  }
}

.rlcip-fByOia-stack {
  flex-flow: column;
  height: 100%;
  display: flex;
}

.rlcip-fByOia-valign-start {
  justify-content: flex-start;
}

.rlcip-fByOia-valign-center {
  justify-content: center;
}

.rlcip-fByOia-valign-end {
  justify-content: flex-end;
}

.rlcip-fByOia-valign-space-between {
  justify-content: space-between;
}

.rlcip-fByOia-halign-start {
  align-items: flex-start;
}

.rlcip-fByOia-halign-center {
  align-items: center;
}

.rlcip-fByOia-halign-end {
  align-items: flex-end;
}

.rlcip-fByOia-gap-none {
}

.rlcip-fByOia-gap-normal {
}

.rlcip-fByOia-gap-medium {
}

.rlcip-fByOia-gap-large {
}

.rlcip-fByOia-gap-xlarge {
}

.rlcip-yUcI6a-item {
  border-bottom: 1px solid var(--configuration-item-border-bottom-color);
  box-sizing: border-box;
  border-left: 2px solid #0000;
  align-items: center;
  width: 100%;
  height: 2.5625rem;
  padding-left: 1.625rem;
  padding-right: .8125rem;
  font-weight: bold;
  transition: all .3s ease-in;
  display: flex;
}

.rlcip-yUcI6a-item:hover {
  cursor: pointer;
  color: #e53238;
  border-left: 2px solid #0000;
}

.rlcip-yUcI6a-active {
  color: var(--item-active-color);
  border-left: 2px solid #0000;
}

.rlcip-yUcI6a-todo {
  color: var(--item-todo-color);
  font-weight: bold;
}

.rlcip-yUcI6a-done {
  color: var(--item-done-color);
}

.rlcip-yUcI6a-disabled {
  cursor: initial;
  transition: none;
}

.rlcip-yUcI6a-disabled:hover {
  cursor: initial;
  color: var(--item-todo-color);
  border-left: 2px solid #0000;
}

@media (width >= 768px) {
  .rlcip-yUcI6a-item:hover, .rlcip-yUcI6a-active {
    border-left: 2px solid var(--item-active-color);
  }

  .rlcip-yUcI6a-disabled:hover {
    cursor: initial;
    color: var(--item-todo-color);
    border-left: 2px solid #0000;
  }
}

.rlcip-XEdTGG-circle {
  box-sizing: border-box;
  border-radius: 50%;
}

.rlcip-XEdTGG-small {
  width: .625rem;
  height: .625rem;
}

.rlcip-XEdTGG-medium {
  width: 1.5625rem;
  height: 1.5625rem;
}

.rlcip-XEdTGG-large {
  width: 2.5625rem;
  height: 2.5625rem;
}

.rlcip-XEdTGG-multicolor {
  background: conic-gradient(red, #fff000, #71dd45, #1943f5, #9a1be8);
}

.rlcip-HaIhXq-menu {
  height: 100%;
  padding: 0 .6rem;
  position: relative;
}

.rlcip-HaIhXq-menu > div:first-child {
  margin-bottom: 9.71rem;
}

.rlcip-HaIhXq-menu span {
  text-transform: uppercase;
}

.rlcip-HaIhXq-menuFooter {
  background-color: var(--menu-footer-background-color);
  color: var(--menu-footer-foreground-color);
  opacity: 0;
  justify-content: space-around;
  align-items: center;
  height: 9.71rem;
  transition-property: all;
  transition-duration: .2s;
  transition-timing-function: ease-in-out;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
}

.rlcip-HaIhXq-menuFooter button {
  text-transform: uppercase;
  width: 100%;
}

.rlcip-HaIhXq-menuFooterOpened {
  opacity: .8;
}

.rlcip-HaIhXq-scrollable {
  overflow: auto;
}

.rlcip-HaIhXq-pipe {
  border-right: 1px solid #ffffff36;
  width: 1px;
  height: 2.85rem;
}

.rlcip-HaIhXq-buttonContainer button {
  color: var(--main-container-foreground-color);
}

@media (width >= 768px) {
  .rlcip-HaIhXq-menu {
    flex-direction: column;
    justify-content: center;
    padding-right: 3.5rem;
    display: flex;
  }

  .rlcip-HaIhXq-menu > div:first-child {
    margin-bottom: 0;
  }

  .rlcip-HaIhXq-menuFooterOpened {
    display: none;
  }
}

.rlcip-piSh-G-container {
  box-sizing: border-box;
  text-transform: uppercase;
  align-items: center;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
}

.rlcip-piSh-G-fadeout {
  opacity: 0;
}

.rlcip-piSh-G-container span {
  text-transform: uppercase;
  white-space: nowrap;
}

.rlcip-piSh-G-circleContainer {
  box-sizing: border-box;
  text-transform: uppercase;
  border: 1px solid #0000;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  transition: all .1s ease-in;
  display: flex;
}

.rlcip-piSh-G-medium {
  width: 2.81rem;
  height: 2.81rem;
}

.rlcip-piSh-G-large {
  width: 3.81rem;
  height: 3.81rem;
}

.rlcip-piSh-G-circleContainer:not([disabled]):hover {
  border: 1px solid var(--circle-container-hover-color);
}

.rlcip-piSh-G-circleContainer[disabled], .rlcip-piSh-G-circleContainer[disabled] + span {
  opacity: .2;
}

.rlcip-piSh-G-selected {
  border: 1px solid var(--circle-container-hover-color);
}

.rlcip-piSh-G-container button {
  color: var(--main-container-foreground-color);
}

button[disabled]:hover {
  cursor: default;
}

.rlcip-u0DPoa-backLabel, .rlcip-u0DPoa-resetLabel {
  text-transform: uppercase;
  display: none;
}

.rlcip-u0DPoa-disabled {
  opacity: .5;
  cursor: none;
  pointer-events: none;
}

.rlcip-u0DPoa-progress {
  padding: .3125rem 1rem 0;
}

.rlcip-u0DPoa-navigation {
  padding: .875rem 1rem;
}

.rlcip-u0DPoa-buttonContainer {
  transition: all .1s ease-in;
}

.rlcip-u0DPoa-buttonContainer:hover {
  text-decoration: underline;
}

.rlcip-u0DPoa-buttonContainer button {
  color: var(--main-container-foreground-color);
}

.rlcip-u0DPoa-button {
  transition: all .15s ease-in-out;
}

.rlcip-u0DPoa-button:disabled, .rlcip-u0DPoa-button[disabled] {
  opacity: .5;
  cursor: auto;
}

@media (width >= 768px) {
  .rlcip-u0DPoa-button:enabled:hover {
    cursor: pointer;
    text-decoration: underline;
  }

  .rlcip-u0DPoa-button:disabled, .rlcip-u0DPoa-button[disabled] {
    opacity: .5;
  }

  .rlcip-u0DPoa-button:disabled:hover, .rlcip-u0DPoa-button[disabled][hover] {
    cursor: auto;
  }

  .rlcip-u0DPoa-backLabel, .rlcip-u0DPoa-resetLabel {
    display: block;
  }

  .rlcip-u0DPoa-progress {
    width: 100%;
    padding: 2.1865rem 3.125rem 0;
  }

  .rlcip-u0DPoa-navigation {
    z-index: 9;
    padding: .875rem 3.125rem;
  }
}

.rlcip-i-mTKq-container {
  background-color: var(--progress-container-background-color);
  width: 100%;
  height: 1px;
}

.rlcip-i-mTKq-progress {
  background-color: var(--progress-color);
  height: 100%;
  transition: width .4s ease-in;
}

.rlcip-i-mTKq-loading {
  background-color: var(--loading-color);
}

.rlcip-EcKjJq-container {
  z-index: -4;
  justify-content: center;
  align-items: center;
  transition: all .2s ease-in;
  position: absolute;
  inset: 0;
  scale: 0;
}

.rlcip-EcKjJq-overlay, .rlcip-EcKjJq-overlayOpened {
  display: none;
}

.rlcip-EcKjJq-containerOpened {
  z-index: 99;
  scale: 1;
}

.rlcip-EcKjJq-modal {
  opacity: 0;
  z-index: -4;
  background-color: var(--menu-footer-background-color);
  border: 1px solid #979797;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  padding: 2.8rem 0 1rem;
  transition: all .2s ease-in 80ms;
  display: flex;
  top: 0;
  left: 0;
  overflow-y: auto;
  scale: 0;
}

.rlcip-EcKjJq-modalOpened {
  opacity: 1;
  z-index: 4;
  scale: 1;
}

.rlcip-EcKjJq-modalContent {
  width: 18rem;
}

.rlcip-EcKjJq-buttonClose {
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: .6rem;
  left: .6rem;
}

.rlcip-EcKjJq-buttonClose:hover {
  cursor: pointer;
  transform: scale(1.1);
}

@media (width >= 768px) {
  .rlcip-EcKjJq-container {
    z-index: -4;
    justify-content: center;
    align-items: center;
    transition: all .2s ease-in;
    display: flex;
    position: absolute;
    inset: 0;
    overflow-y: hidden;
  }

  .rlcip-EcKjJq-containerOpened {
    z-index: 99;
    overflow-y: auto;
    scale: 1;
  }

  .rlcip-EcKjJq-overlay {
    background: var(--main-container-background-color);
    opacity: 0;
    transition: all .1s ease-in;
    display: block;
    position: absolute;
    inset: 0;
  }

  .rlcip-EcKjJq-overlayOpened {
    opacity: .6;
    display: block;
    inset: 0;
  }

  .rlcip-EcKjJq-modal {
    bottom: unset;
    align-items: center;
    width: 80%;
    height: 80%;
    padding: 0;
    position: relative;
  }

  .rlcip-EcKjJq-modalOpened {
    opacity: 1;
    z-index: 4;
  }

  .rlcip-EcKjJq-modalContent {
    width: 100%;
    height: auto;
    padding: 0 1.81rem;
  }

  .rlcip-EcKjJq-buttonClose {
    left: auto;
    right: .6rem;
  }
}

input[type="radio"] {
  display: none;
}

input[type="radio"]:checked + div:after {
  opacity: 1;
}

.rlcip-pgq3JW-customRadio {
  cursor: pointer;
  border: 1px solid var(--button-primary-background-color);
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 1.43rem;
  height: 1.43rem;
  display: flex;
  position: relative;
}

.rlcip-pgq3JW-customRadio:after {
  content: "";
  background: var(--button-primary-background-color);
  opacity: 0;
  border-radius: 50%;
  width: .4rem;
  height: .4rem;
  transition: opacity .2s;
}

.rlcip-pgq3JW-text {
  cursor: pointer;
}

@media (width >= 768px) {
  .rlcip-pgq3JW-text {
    letter-spacing: .203rem;
    font-size: .875rem;
  }
}

.rlcip-v9JNTW-mainContainer {
  flex-direction: column;
  gap: 2rem;
  display: flex;
}

.rlcip-v9JNTW-zoomed3d {
  background-color: gray;
  width: 17rem;
  height: 14.25rem;
  margin: auto;
}

.rlcip-v9JNTW-divider {
  border-bottom-color: var(--configuration-item-border-bottom-color);
  border-style: inset;
  border-width: 0 0 1px;
  width: 100%;
  margin: 1.25rem 0;
}

.rlcip-v9JNTW-signatureColorContainer {
  opacity: 0;
  height: 0;
  padding: 0 1.625rem;
  transition: all .2s ease-in;
  overflow-y: hidden;
}

.rlcip-v9JNTW-signatureContainerOpened {
  opacity: 1;
  overflow-y: inherit;
  height: 5.0625rem;
  padding: 1.25rem 0 0 1.625rem;
}

.rlcip-v9JNTW-textContainer {
  opacity: 0;
  height: 0;
  padding-top: 0;
  transition: all .2s ease-in;
  overflow-y: hidden;
}

.rlcip-v9JNTW-textContainerOpened {
  opacity: 1;
  overflow-y: inherit;
  height: auto;
  padding-top: 1.4375rem;
}

.rlcip-v9JNTW-textColorContainer {
  padding: 2.75rem 1.625rem 0;
}

.rlcip-v9JNTW-imageUploaderContainer {
  opacity: 0;
  flex-flow: column;
  align-items: center;
  gap: 1rem;
  height: 0;
  transition: all .2s ease-in;
  display: flex;
  overflow-y: hidden;
}

.rlcip-v9JNTW-imageUploaderContainerOpened {
  opacity: 1;
  overflow-y: inherit;
  height: auto;
  padding-top: 1rem;
}

.rlcip-v9JNTW-side {
  cursor: pointer;
  border-bottom: 2px solid #0000;
}

.rlcip-v9JNTW-activeSide {
  border-bottom: 2px solid var(--button-primary-background-color);
}

@media (width >= 768px) {
  .rlcip-v9JNTW-mainContainer {
    flex-direction: row;
    gap: 1rem;
    height: 28rem;
    display: flex;
  }

  .rlcip-v9JNTW-zoomed3d {
    margin: unset;
    background-color: gray;
    width: 33rem;
    height: 100%;
  }

  .rlcip-v9JNTW-selectionContainer {
    flex-grow: 1;
    height: 100%;
  }

  .rlcip-v9JNTW-signatureColorContainer {
    padding-left: 1.625rem;
    transition: all .2s ease-in;
  }

  .rlcip-v9JNTW-textContainer {
    padding-top: 0;
    padding-left: 1.625rem;
    transition: all .2s ease-in;
  }

  .rlcip-v9JNTW-textContainerOpened {
    padding-top: 1.4375rem;
  }

  .rlcip-v9JNTW-textColorContainer {
    padding: 1.625rem 0 0;
  }

  .rlcip-v9JNTW-signatureContainerOpened {
    padding-top: 1rem;
  }

  .rlcip-v9JNTW-imageUploaderContainer {
    flex-flow: row;
  }
}

input[type="text"] {
  border-left: 1px solid var(--input-text-left-border-color);
  letter-spacing: .2375rem;
  box-sizing: border-box;
  height: 2.625rem;
  padding: .375rem 1.375rem;
  font-size: .875rem;
}

.rlcip-V_T9ya-fileUploadContainer {
  border: 1px dashed #696767;
  width: 100%;
}

.rlcip-V_T9ya-dropZone {
  justify-content: center;
  align-items: center;
  width: 238px;
  height: 58px;
  display: flex;
}

@media (width >= 768px) {
  .rlcip-V_T9ya-fileUploadContainer {
    width: 238px;
  }
}

.rlcip-5IwsjG-hidden {
  position: absolute;
  top: 100%;
  left: 100%;
}

.rlcip-5IwsjG-configurator {
  width: 100%;
  height: 100%;
  position: static;
  transform: translate(100%, 100%);
}

.rlcip-5IwsjG-loaded {
  transform: translate(0);
}

.rlcip-5IwsjG-overlay {
  z-index: 12;
  background: var(--main-container-background-color);
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
  display: flex;
  position: absolute;
  inset: 0;
}

.rlcip-5IwsjG-loadingContainer {
  width: 24rem;
}

a.rlcip-5IwsjG-download {
  font-size: .875rem;
  text-decoration: underline;
}

a.rlcip-5IwsjG-download:hover {
  cursor: pointer;
  text-decoration: none;
}

.rlcip-bcC-NW-loading {
  color: var(--logo-color);
}

/*# sourceMappingURL=index.css.map */
